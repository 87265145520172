/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";

// components
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import MenuBar from "components/Menubar";
import Preview from "./Preview";
import ToastModal from "components/ToastModal";
import { toast } from "react-toastify";

import { getTournamentDetailsById } from "services/tournamentsV3";

// styles
import styles from "./style.module.scss";

const index = ({ tournamentId }: any) => {
  const [tournamentDetails, setTournamentDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [showToastModal, setShowToastModal] = useState(false);

  const getTournementDetails = async ({ tournamentId }: any) => {
    setLoading(true);
    const response = await getTournamentDetailsById(tournamentId);
    if (response && !response?.isError) {
      setTournamentDetails(response?.data);
      setLoading(false);
    } else {
      if (response?.reason === "TIMEOUT_ERROR") {
        toast.error(response?.errorMessage);
        return;
      }
      setShowToastModal(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTournementDetails({ tournamentId });
  }, [tournamentId]);

  if (!tournamentDetails) {
    return (
      <ToastModal
        isModalOpen={showToastModal}
        setIsModalOpen={setShowToastModal}
        message={"Could not load the tournament. Please try again later."}
        navigationLink={`/tournament-v3`}
        variant={"fetch-failure"}
      />
    );
  }

  if (loading) {
    return <BackdropLoader loaderState={loading} />;
  }

  return (
    <div className={styles["outer-preview-container"]}>
      <MenuBar menuBgColor="rgba(0, 0, 0, 0.1)" />
      <div className={styles["main-preview-container"]}>
        <Preview tournamentId={tournamentId} data={tournamentDetails} />
      </div>
    </div>
  );
};

export default index;
