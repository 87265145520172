import { HandleLogOut, getAccessToken } from "utils/Helper";

const error401Response = {
  isError: true,
  reason: "Your session has timed out! Please login again",
};

const TIMEOUT_DURATION = 30000;
const TIMEOUT_ERROR = "TIMEOUT_ERROR";
const TIMEOUT_ERROR_MESSAGE =
  "We couldn't connect to the network. Please check your internet and try again.";

const createTimeoutPromise = (timeout: number) =>
  new Promise<any>((_, reject) =>
    setTimeout(() => reject(new Error(TIMEOUT_ERROR)), timeout)
  );

const POST = async (path: string, data: any, headers?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  const executePost = fetch(path, {
    method: "POST",
    headers: requestHeaders,
    body: JSON.stringify(data),
  });

  try {
    // Race between fetch and timeout
    response = await Promise.race([
      executePost,
      createTimeoutPromise(TIMEOUT_DURATION),
    ]);
  } catch (error: any) {
    if (error?.message === TIMEOUT_ERROR) {
      console.error(TIMEOUT_ERROR);
      return {
        isError: true,
        reason: TIMEOUT_ERROR,
        errorMessage: TIMEOUT_ERROR_MESSAGE,
      };
    }
    // Handle other errors
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response?.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const GET = async (path: string, params?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  const urlParams = new URLSearchParams(params);
  const executeGet = fetch(path + "?" + urlParams, {
    method: "GET",
    headers: requestHeaders,
  });

  try {
    // Race between fetch and timeout
    response = await Promise.race([
      executeGet,
      createTimeoutPromise(TIMEOUT_DURATION),
    ]);
  } catch (error: any) {
    if (error?.message === TIMEOUT_ERROR) {
      console.error(TIMEOUT_ERROR);
      return {
        isError: true,
        reason: TIMEOUT_ERROR,
        errorMessage: TIMEOUT_ERROR_MESSAGE,
      };
    }
    // Handle other errors
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const GET2 = async (path: string, params?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  try {
    response = await fetch(path, {
      method: "GET",
      headers: requestHeaders,
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const PUT = async (path: string, data: any, headers?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  const executePut = fetch(path, {
    method: "PUT",
    headers: requestHeaders,
    body: JSON.stringify(data),
  });

  try {
    // Race between fetch and timeout
    response = await Promise.race([
      executePut,
      createTimeoutPromise(TIMEOUT_DURATION),
    ]);
  } catch (error: any) {
    if (error.message === TIMEOUT_ERROR) {
      console.error(TIMEOUT_ERROR);
      return {
        isError: true,
        reason: TIMEOUT_ERROR,
        errorMessage: TIMEOUT_ERROR_MESSAGE,
      };
    }

    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

const PATCH = async (path: string, data: any, headers?: any) => {
  let response: any;
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Authorization", `Bearer ${getAccessToken()}`);

  const executePatch = fetch(path, {
    method: "PATCH",
    headers: requestHeaders,
    body: JSON.stringify(data),
  });

  try {
    // Race between fetch and timeout
    response = await Promise.race([
      executePatch,
      createTimeoutPromise(TIMEOUT_DURATION),
    ]);
  } catch (error: any) {
    if (error.message === TIMEOUT_ERROR) {
      console.error(TIMEOUT_ERROR);
      return {
        isError: true,
        reason: TIMEOUT_ERROR,
        errorMessage: TIMEOUT_ERROR_MESSAGE,
      };
    }

    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return error401Response;
  }
  let resultText = await response.text();
  let result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

export { POST, GET, GET2, PUT, PATCH };
