/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import styles from "./style.module.scss";
import tournaments from "assets/MenuBar/tournaments.svg";
import players from "assets/MenuBar/players.svg";
import teams from "assets/MenuBar/teams.svg";
import scorers from "assets/MenuBar/scorers.svg";
import grounds from "assets/MenuBar/grounds.svg";

import tournamentsHighlighted from "assets/MenuBar/tournamentsHighlighted.svg";
import playersHighlighted from "assets/MenuBar/playersHighlighted.svg";
import teamsHighlighted from "assets/MenuBar/teamsHighlighted.svg";
import scorersHighlighted from "assets/MenuBar/scorersHighlighted.svg";
import groundsHighlighted from "assets/MenuBar/groundsHighlighted.svg";
import { useNavigate } from "react-router-dom";

import ToastModal from "components/ToastModal";

interface IProps {
  redirectionCheckRequired?: boolean;
  menuBgColor?: string;
}

const index = ({ redirectionCheckRequired, menuBgColor }: IProps) => {
  const navigate = useNavigate();

  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastNavigationLink, setToastNavigationLink] = useState("");

  const showSuccessToast = (text: string, link: string) => {
    setToastMessage(text);
    setToastNavigationLink(link);
    setSuccessToast(true);
  };

  const handleRedirect = (link: string) => {
    if (redirectionCheckRequired) {
      showSuccessToast(
        "Would you really like to exit this page? All your unsaved changes will be lost.",
        link
      );
      return;
    }
    navigate(link);
  };

  const currentPath: any = window.location.pathname;

  const menuConfig = [
    {
      name: "Tournaments",
      link: "/tournament-v3",
      logo: tournaments,
      logoSelected: tournamentsHighlighted,
    },

    {
      name: "Teams",
      link: null,
      logo: teams,
      logoSelected: teamsHighlighted,
    },

    {
      name: "Players",
      link: null,
      logo: players,
      logoSelected: playersHighlighted,
    },

    {
      name: "Scorers",
      link: null,
      logo: scorers,
      logoSelected: scorersHighlighted,
    },

    {
      name: "Grounds",
      link: null,
      logo: grounds,
      logoSelected: groundsHighlighted,
    },
  ];

  return (
    <>
      <div className={styles["menu-bar-container"]}>
        <div
          className={styles["menu-bar-wrapper"]}
          style={{
            backgroundColor: menuBgColor ? menuBgColor : "",
          }}
        >
          {menuConfig.map((item, index) => {
            return (
              <div
                key={index}
                className={styles["menu-item"]}
                onClick={() => {
                  if (item?.link) {
                    handleRedirect(item.link);
                    return;
                  }
                  return;
                }}
                style={{
                  color: currentPath.includes(item?.link) ? "#fff" : "",
                }}
              >
                <div>
                  <img
                    src={
                      currentPath.includes(item?.link)
                        ? item.logoSelected
                        : item.logo
                    }
                    alt={item.name}
                  />
                </div>
                {item.name}
              </div>
            );
          })}
        </div>
      </div>

      <ToastModal
        isModalOpen={successToast}
        setIsModalOpen={setSuccessToast}
        message={toastMessage}
        navigationLink={toastNavigationLink}
        variant={"warning"}
      />
    </>
  );
};

export default index;
