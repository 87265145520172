import React from "react";
import styles from "./style.module.scss";
import gully91 from "assets/gully91.svg";

interface indexProps {
  navBgColor?: string;
}

const index = ({ navBgColor }: indexProps) => {
  console.log("navBgColor", navBgColor);
  const redirectToHome = () => {
    window.location.href = "/";
  };
  return (
    <div
      className={styles["navbar-container"]}
      style={{
        background: navBgColor ? navBgColor : "",
      }}
    >
      <div className={styles["navbar-wrapper"]}>
        <div className={styles["brand-logo"]}>
          <img src={gully91} alt="brand-logo" onClick={redirectToHome} />
        </div>
      </div>
    </div>
  );
};

export default index;
