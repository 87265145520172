import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import { toast } from "react-toastify";

// services
import { uploadImagetoS3 } from "services/tournamentsV3";

import imgUpload from "assets/uploadIcon.svg";

interface ImageUploadProps {
  inputKey: string;
  isRequired: boolean;
  label: string;
  presentValue: string; // Initially empty or a URL if replacing
  placeholder: string;
  isEditable?: boolean;
  isFullWidth?: boolean;
  onInputChange: any;
  imageWidth?: string;
}

const ImageUpload = ({
  inputKey,
  isRequired,
  label,
  presentValue,
  placeholder,
  isEditable,
  isFullWidth,
  onInputChange,
  imageWidth,
}: ImageUploadProps) => {
  const [image, setImage] = useState<string | null>(presentValue || null);
  const fileInputRef = useRef<HTMLInputElement>(null); // Reference to the file input

  useEffect(() => {
    setImage(presentValue || null);
  }, [presentValue]);

  // Trigger the file input when clicking on "Replace Image" button
  const handleReplaceClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      let result = await uploadImagetoS3(file);

      if (result && !result.isError) {
        const imageUrl = result?.data?.image_url;
        setImage(imageUrl); // Set the uploaded image URL
        onInputChange(imageUrl); // Send image URL to the parent
        // toast.success("Successfully Uploaded!");
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      // toast.error("There was some error uploading! Please try again.");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const MAX_FILE_SIZE = 5 * 1024 * 1024;
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size exceeds 5MB. Please upload a smaller file.");
        return;
      }
      handleImageUpload(file); // Upload to S3 and update state
    }
  };

  // const handleDownloadClick = () => {
  //   if (image) {
  //     const link = document.createElement("a");
  //     link.href = image; // URL of the image
  //     link.download = image.split("/").pop() || "downloaded-image"; // Extract filename or set a default name
  //     document.body.appendChild(link);
  //     link.click(); // Programmatically click the link to trigger the download
  //     document.body.removeChild(link); // Clean up the DOM
  //   } else {
  //     toast.error("No image available to download.");
  //   }
  // };

  return (
    <div
      className={styles["image-upload-container"]}
      style={{ width: isFullWidth ? "100%" : "" }}
    >
      <div className={styles["label"]}>
        <label
          style={{
            fontFamily: "Lato",
          }}
        >
          {label}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </label>
      </div>

      {image ? (
        <div
          className={styles["image-preview"]}
          style={{
            width: imageWidth || "142px",
          }}
        >
          <img
            src={image}
            alt="Uploaded"
            className={styles["uploaded-image"]}
            onClick={handleReplaceClick}
          />
        </div>
      ) : (
        <div className={styles["upload-image-container"]}>
          <button
            type="button"
            className={styles["upload-button"]}
            style={{
              width: imageWidth || "142px",
            }}
            onClick={handleReplaceClick}
            disabled={!isEditable}
          >
            <img src={imgUpload} alt="" />
          </button>
        </div>
      )}

      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
        disabled={!isEditable}
      />
    </div>
  );
};

export default ImageUpload;
