import React from "react";
import styles from "./style.module.scss";

const index = ({ data }: any) => {
  return (
    <div
      className={styles["video-container"]}
      onClick={() => {
        data?.url && window.open(data?.url, "_blank");
      }}
    >
      <div className={styles["video-thumbnail"]}>
        <img src={data?.thumbnail} alt="thumbnail" />
      </div>
      <div className={styles["video-desc"]}>
        <div className={styles["video-title"]}>{data?.title}</div>
        <div className={styles["video-sub-title"]}>{data?.subTitle}</div>
      </div>
    </div>
  );
};

export default index;
