/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import styles from "./style.module.scss";

const index = ({ data }: any) => {
  const [readMore, setReadMore] = useState(true);

  return (
    <div className={styles["tournament-about-container"]}>
      <div className={styles["tournament-about-wrapper"]}>
        <div className={styles["tournament-about-heading"]}>
          About the Tournament
        </div>
        <div className={styles["tournament-about-desc"]}>
          <div className={readMore ? styles["text-clamp"] : styles["text"]}>
            {data?.description}
          </div>

          <div
            className={styles["read-more"]}
            onClick={() => setReadMore(!readMore)}
          >
            Read More
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
