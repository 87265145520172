/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useState } from "react";

import styles from "./style.module.scss";

interface IProps {
  reqData: any;
  setReqData: any;
}

const index = ({ reqData, setReqData }: IProps) => {
  const [filterCategory, setFilterCategory] = useState("status");
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>(reqData?.filter);

  const filterCategories: any = [
    // {
    //   name: "sport",
    //   label: "Sport",
    // },

    {
      name: "status",
      label: "Status",
    },

    // {
    //   name: "hostCity",
    //   label: "City",
    // },
  ];

  const filterOptions: any = {
    sport: [
      {
        name: "INDOOR_CRICKET",
        label: "Indoor",
      },
      {
        name: "CRICKET",
        label: "Cricket",
      },
      {
        name: "FOOTBALL",
        label: "Football",
      },
    ],
    status: [
      {
        name: "SCHEDULED",
        label: "Upcoming",
      },
      {
        name: "ONGOING",
        label: "Ongoing",
      },
      {
        name: "RESULT",
        label: "Result",
      },
      {
        name: "CANCELLED",
        label: "Cancelled",
      },
    ],
  };

  const toggleOption = (category: string, option: string) => {
    setSelectedFilters((prev) => {
      const currentSelections = prev[category] || [];
      const isSelected = currentSelections.includes(option);
      const updatedSelections = isSelected
        ? currentSelections.filter((item) => item !== option) // Remove if already selected
        : [...currentSelections, option]; // Add if not selected

      return { ...prev, [category]: updatedSelections };
    });
  };

  const selectAllOptions = (category: string) => {
    const allOptions = filterOptions[category].map(
      (option: any) => option.name
    );
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: allOptions,
    }));
  };

  const applyFilters = () => {
    setReqData((prev: any) => ({
      ...prev,
      filter: selectedFilters,
    }));
  };

  return (
    <div className={styles["filter-container"]}>
      {/* Filter Categories */}
      <div className={styles["filter-categories"]}>
        {filterCategories?.map((category: any) => {
          return (
            <div
              key={category?.name}
              className={`${styles["filter-category"]} ${
                filterCategory === category?.name
                  ? styles["filter-category-active"]
                  : ""
              }`}
              onClick={() => setFilterCategory(category?.name)}
            >
              {category?.label}
            </div>
          );
        })}
      </div>

      {/* Filter Options */}
      <div className={styles["filter-options"]}>
        <div
          className={styles["filter-option"]}
          onClick={() => selectAllOptions(filterCategory)}
        >
          Select All
        </div>
        {filterOptions[filterCategory]?.map((option: any) => {
          return (
            <div
              key={option?.name}
              // className={styles["filter-option"]}
              className={`${styles["filter-option"]} ${
                selectedFilters[filterCategory]?.includes(option.name)
                  ? styles["filter-option-selected"]
                  : ""
              }`}
              onClick={() => toggleOption(filterCategory, option.name)}
            >
              {option?.label}
              <input
                type="checkbox"
                checked={selectedFilters[filterCategory]?.includes(option.name)}
                aria-label={`Filter option: ${option.label}`}
              />
            </div>
          );
        })}
        <div className={styles["filter--apply-button"]} onClick={applyFilters}>
          Apply
        </div>
      </div>
    </div>
  );
};

export default index;
