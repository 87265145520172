/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import styles from "./style.module.scss";

import backButton from "assets/back-button.svg";
import ToastModal from "components/ToastModal";

interface indexProps {
  formType: string;
  alternateHeading?: string;
}

const index = ({ formType, alternateHeading }: indexProps) => {
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastNavigationLink, setToastNavigationLink] = useState("");

  const showSuccessToast = (text: string, link: string) => {
    setToastMessage(text);
    setToastNavigationLink(link);
    setSuccessToast(true);
  };

  const handleBackButton = () => {
    showSuccessToast(
      "Would you really like to exit this page? All your unsaved changes will be lost.",
      window?.history?.length > 1 ? "go-back" : "/tournament-v3"
    );
    return;
  };

  const headingMapper: any = {
    create: "Add Tournament",
    view: "View Tournament",
    edit: "Edit Tournament",
  };
  return (
    <>
      <div className={styles["page-heading-container"]}>
        <div className={styles["name"]}>
          <img
            src={backButton}
            alt="back-button"
            className={styles["back-button"]}
            onClick={handleBackButton}
          />
          {alternateHeading || headingMapper[formType]}
        </div>

        {/* <div className={styles["utility-button"]}></div> */}
      </div>

      <ToastModal
        isModalOpen={successToast}
        setIsModalOpen={setSuccessToast}
        message={toastMessage}
        navigationLink={toastNavigationLink}
        variant={"warning"}
      />
    </>
  );
};

export default index;
