import React from "react";
import styles from "./style.module.scss";
import AddEditButton from "components/AddEditButton";
import MatchCard from "./MatchCard";
import NoEntity from "../NoEntity";

const index = ({ data, tournamentId }: any) => {
  return (
    <div className={styles["matches-container"]}>
      <div className={styles["matches-heading-wapper"]}>
        <div className={styles["matches-heading"]}>Matches</div>
        <AddEditButton
          text="Add"
          editProperty={"Matches"}
          navigationLink={`/${tournamentId}/matches/create`}
        />
      </div>
      <div className={styles["matches-wrapper"]}>
        {data?.length ? (
          data?.map((match: any, index: number) => (
            <MatchCard key={index} data={match} tournamentId={tournamentId} />
          ))
        ) : (
          <NoEntity text={"Matches"} />
        )}
      </div>
    </div>
  );
};

export default index;
