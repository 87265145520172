/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import { RenderField } from "../RenderFieldMultiStep";
import { getVenueListPaginated } from "services/tournamentsNew";

import styles from "./style.module.scss";

const Step = ({
  heading,
  inputFields,
  unregister,
  formType,
  errors,
  setIsSubmitAllowed,
  setAdditionalEntity,
  newTeam,
}: any) => {
  const { control, getValues } = useFormContext();

  // const [name, setName] = useState(() => getValues("name"));
  // const watchedName = useWatch({ control, name: "name" });
  // useEffect(() => {
  //   const updatedName = getValues("name");
  //   setName(updatedName);
  // }, [watchedName, getValues("name")]);

  // const restrictedFields: any = {
  //   Mahesh: ["matchCountRamesh", "matchCountJayesh"],
  //   Ramesh: ["matchCountMahesh", "matchCountJayesh"],
  //   Jayesh: ["matchCountRamesh", "matchCountMahesh"],
  //   default: ["matchCountMahesh", "matchCountRamesh", "matchCountJayesh"],
  // };

  // const watchgroups = useWatch({ control, name: "groups" });
  // useEffect(() => {
  //   console.log("groups", watchgroups);
  // }, [watchgroups]);

  const [dropdownOptions, setDropdownOptions] = useState<{ venue: any[] }>({
    venue: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchOptions = async (inputKey: string) => {
    if (inputKey === "venue") {
      try {
        setLoading(true);
        setError(null);

        const reqData = { page: 1, pageSize: 50 };
        const result = await getVenueListPaginated(reqData);

        if (result && !result.isError) {
          const venueList = result.data.list.map((venue: any) => ({
            label: venue.name,
            id: venue.venueId,
          }));
          setDropdownOptions((prev) => ({ ...prev, venue: venueList }));
        } else {
          setError("No venues found");
        }
      } catch (error) {
        console.error("Error fetching venues:", error);
        setError("No venues found");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOptions("venue");
  }, []);

  const isFullWidth = true;

  return (
    <div className={styles["step-wrapper"]}>
      {/* <h2 className={styles["step-heading"]}>{heading}</h2> */}

      <div className={styles["step-container"]}>
        {inputFields.map((field: any) => {
          // const restrictedKeys =
          //   restrictedFields[name] || restrictedFields.default;

          // if (restrictedKeys.includes(field.inputKey)) {
          //   return null;
          // }

          return (
            <div
              key={field}
              style={{
                width: field?.widgetType === "custom" ? "100%" : "48%",
              }}
            >
              <Controller
                key={field.inputKey}
                name={field.inputKey}
                defaultValue={field.widgetAttributes.details.value}
                control={control}
                rules={{
                  required: field.widgetAttributes.isMandatory
                    ? "This field is required"
                    : false,
                }}
                render={({ field: controllerField }) => (
                  <>
                    {RenderField(
                      field,
                      controllerField.value,
                      dropdownOptions,
                      controllerField.onChange,
                      control,
                      unregister,
                      formType,
                      isFullWidth,
                      errors[field.inputKey],
                      setIsSubmitAllowed,
                      setAdditionalEntity,
                      newTeam
                    )}
                  </>
                )}
              />
              <div className={styles["error-text-container"]}>
                {errors[field.inputKey] && (
                  <p className={styles["error-text"]}>
                    {errors[field.inputKey]?.message ||
                      "This field is mandatory"}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Step;
