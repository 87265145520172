import React from "react";
import styles from "./style.module.scss";

interface IProps {
  text: string;
  extStyles?: any;
}

const index = ({ text, extStyles }: IProps) => {
  return (
    <div className={styles["status"]} style={extStyles}>
      <div className={styles[`status-dot-${text}`]}></div>
      {text}
    </div>
  );
};

export default index;
