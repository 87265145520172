/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import styles from "./style.module.scss";

// components
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

// services
import { createTournament } from "services/tournamentsV3";

import eyeClosedGray from "assets/Tournament/eyeClosedGray.svg";
import eyeOpenGray from "assets/Tournament/eyeOpenGray.svg";

const ShowHide = ({ item, index }: any) => {
  const [isEyeOpen, setIsEyeOpen] = useState(item?.isActive);
  const [loading, setLoading] = useState(false);

  const getBgColor = (index: number) => {
    if (index % 2 === 0) {
      return "rgba(0, 0, 0, 0.22)";
    } else {
      return "rgba(255, 255, 255, 0.04)";
    }
  };

  const editTournament = async (formData: any) => {
    setLoading(true);
    let result = await createTournament(formData);
    if (result && !result.isError) {
      setIsEyeOpen(!isEyeOpen);
      setLoading(false);
    } else {
      if (result?.reason === "TIMEOUT_ERROR") {
        toast.error(result?.errorMessage);
        return;
      }
      toast.error("Something went wrong,  Please try again later.");
      setLoading(false);
    }
  };

  const handleHideClick = (isActive: boolean, tournamentId: string) => {
    const formData = {
      tournamentId: tournamentId,
      isActive: isEyeOpen ? "False" : "True",
    };

    editTournament(formData);
  };

  return (
    <div
      className={styles["row-show-hide"]}
      style={{ backgroundColor: getBgColor(index) }}
      onClick={() => {
        handleHideClick(item?.isActive, item?.tournamentId);
      }}
    >
      {loading ? (
        <Oval
          visible={true}
          height="20"
          width="20"
          color="#c0c0c0"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
          secondaryColor={"#c0c0c0"}
        />
      ) : (
        <img
          src={isEyeOpen ? eyeOpenGray : eyeClosedGray}
          alt=""
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  );
};

export default ShowHide;
