/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import Modal from "react-modal";
import { useForm, FormProvider } from "react-hook-form";
import styles from "./style.module.scss";

import { createPlayerInputFields } from "./inputFields";

import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import { toast } from "react-toastify";

import { Controller } from "react-hook-form";
// import { useFormContext } from "react-hook-form";
import crossGreen from "assets/crossGreen.svg";

import { RenderField } from "components/TournamentV3/Registration/RenderFieldMultiStep";
import { createPlayerByMandatoryDetails } from "services/tournamentsV3";

const index = ({
  isModalOpen,
  setIsModalOpen,
  setNewTeam,
  componentId,
}: any) => {
  const methods = useForm();
  const {
    formState: { errors },
    reset,
  } = methods;

  const [loading, setLoading] = useState(false);

  // const { control } = useFormContext();

  const createNewPlayer = async (data: any) => {
    setLoading(true);
    const result = await createPlayerByMandatoryDetails(data);
    if (result && !result?.isError) {
      // save id and label of the created team
      setNewTeam({
        data: {
          id: result?.data?.playerId,
          label: result?.data?.displayName,
        },
        componentId: componentId,
      });
      reset();
      setIsModalOpen(["none", null]);
    }
    //
    else {
      if (result?.reason === "TIMEOUT_ERROR") {
        toast.error(result?.errorMessage);
        return;
      }
      toast.error(result?.error);
      setIsModalOpen(["none", null]);
    }
    setLoading(false);
  };

  const onSubmit = (data: any) => {
    data["cityId"] = data?.city?.id;
    delete data["city"];
    createNewPlayer(data);
  };

  const customStyles = {
    content: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      background: "rgba(11, 11, 11, 0.50)",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      border: "none",
      borderRadius: 0,
      inset: 0,
      backdropFilter: "blur(2px)",
    },
  };

  const handleModalClose = () => {
    setIsModalOpen(["none", null]);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleModalClose}
      contentLabel="Create Player"
      style={customStyles} // Apply custom styles here
    >
      <div className={styles["create-player-outer-container"]}>
        <div className={styles["close-modal-icon"]} onClick={handleModalClose}>
          <img src={crossGreen} alt="cross" />
        </div>
        {loading === true ? (
          <BackdropLoader loaderState={loading} />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className={styles["create-player-form-container"]}
            >
              <div className={styles["create-player-form-wrapper"]}>
                {createPlayerInputFields.map((field: any) => {
                  return (
                    <Controller
                      key={field.inputKey}
                      name={field.inputKey}
                      defaultValue={field.widgetAttributes.details.value}
                      // control={control}
                      rules={{
                        required: field.widgetAttributes.isMandatory
                          ? "This field is required"
                          : false,
                      }}
                      render={({ field: controllerField }) => (
                        <>
                          {RenderField(
                            field,
                            controllerField.value,
                            [],
                            controllerField.onChange,
                            // control,
                            errors[field.inputKey]
                          )}
                        </>
                      )}
                    />
                  );
                })}
              </div>

              <div className={styles["create-player-buttons-container"]}>
                <button
                  type="submit"
                  // disabled={!methods.formState.isValid}
                  style={{
                    backgroundColor: !methods.formState.isValid
                      ? "#dbeb9a"
                      : "",
                  }}
                  className={styles["create-player-submit-button"]}
                >
                  Submit
                </button>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </Modal>
  );
};

export default index;
