export const contract = {
  tournamentDetails: {
    id: "", // masked
    name: "Corporate all stars championship",
    shortName: "CAS",
    logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_52ea6562-1054-4f93-8809-afb9751dee4b.png",
    bannerMobile: "",
    bannerDesktop: "",
    venue: " Insportz Multisports Stadium",
    city: "Bengaluru",
    status: "UPCOMING", // display status
    social: [
      {
        name: "facebook", // needed???
        url: "https://www.facebook.com/",
        icon: "",
      },

      {
        name: "instagram", // needed???
        url: "https://www.facebook.com/",
        icon: "",
      },

      {
        name: "youtube", // needed???
        url: "https://www.facebook.com/",
        icon: "",
      },
    ],
  },

  about: {
    description:
      "Corporate All Stars Championship, the first-of-its-kind Indoor Cricket tournament in India, set to take Bengaluru by storm! The tournament kicks off on the 22nd of September and runs exclusively on",
  },

  teams: [
    {
      id: "123",
      name: "Chennai Super Kings",
      shortName: "CSK",
      logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
    },

    {
      id: "456",
      name: "Chennai Super Kings",
      shortName: "CSK",
      logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
    },

    {
      id: "789",
      name: "Chennai Super Kings",
      shortName: "CSK",
      logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
    },
  ],

  sponsors: [
    {
      name: "NIKE",
      logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_32405a93-13a8-4cd0-a6ac-28299d14a455.jpeg",
      url: "",
    },

    {
      name: "NIKE",
      logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_32405a93-13a8-4cd0-a6ac-28299d14a455.jpeg",
      url: "",
    },

    {
      name: "NIKE",
      logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_32405a93-13a8-4cd0-a6ac-28299d14a455.jpeg",
      url: "",
    },
  ],

  matches: [
    {
      status: "RESULT", // display status
      type: "Final", // final/semi-final/league
      date: "22nd Dec", // display date ???
      startTime: "9:00AM", // display time ???

      team1: {
        id: "", // masked
        name: "Avengers",
        shortName: "Avengers",
        logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_d6f402be-3dd3-46b5-893f-8f328ac0ca26.png",
      },

      team2: {
        id: "", // masked
        name: "Justice League",
        shortName: "Justice",
        logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_2931f2d6-360c-4560-a5b1-b3c7ea626794.png",
      },
    },

    {
      status: "RESULT", // display status
      type: "Final", // final/semi-final/league
      date: "22nd Dec", // display date ???
      startTime: "9:00AM", // display time ???

      team1: {
        id: "", // masked
        name: "Avengers",
        shortName: "Avengers",
        logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_d6f402be-3dd3-46b5-893f-8f328ac0ca26.png",
      },

      team2: {
        id: "", // masked
        name: "Justice League",
        shortName: "Justice",
        logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_2931f2d6-360c-4560-a5b1-b3c7ea626794.png",
      },
    },

    {
      status: "RESULT", // display status
      type: "Final", // final/semi-final/league
      date: "22nd Dec", // display date ???
      startTime: "9:00AM", // display time ???

      team1: {
        id: "", // masked
        name: "Avengers",
        shortName: "Avengers",
        logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_d6f402be-3dd3-46b5-893f-8f328ac0ca26.png",
      },

      team2: {
        id: "", // masked
        name: "Justice League",
        shortName: "Justice",
        logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_2931f2d6-360c-4560-a5b1-b3c7ea626794.png",
      },
    },
  ],

  metaData: [
    {
      title: "Total Players",
      subTitle: "in the tournament",
      value: 28,
      icon: "",
    },

    {
      title: "Total teams",
      subTitle: "in the tournament",
      value: 29,
      icon: "",
    },

    {
      title: "Total Matches",
      subTitle: "in the tournament",
      value: 30,
      icon: "",
    },
  ],

  videos: [
    {
      thumbnail:
        "https://gully91.com/static/media/PlayersDesktop.29ba0f691bbdc505bfc4.webp",
      title: "Seora Titans VS Hardwire Bulls",
      subTitle: "Experience the excitement of the 1Ladakh FC vs Tibetan",
    },
    {
      thumbnail:
        "https://gully91.com/static/media/PlayersDesktop.29ba0f691bbdc505bfc4.webp",
      title: "Seora Titans VS Hardwire Bulls",
      subTitle: "Experience the excitement of the 1Ladakh FC vs Tibetan",
    },
    {
      thumbnail:
        "https://gully91.com/static/media/PlayersDesktop.29ba0f691bbdc505bfc4.webp",
      title: "Seora Titans VS Hardwire Bulls",
      subTitle: "Experience the excitement of the 1Ladakh FC vs Tibetan",
    },
  ],

  pointsTable: [
    {
      groupName: "Group 1",
      teams: [
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Gokulam Kerala",
          points: 12,
          isQualified: true,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team A",
          points: 9,
          isQualified: true,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team B",
          points: 8,
          isQualified: false,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team C",
          points: 6,
          isQualified: false,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team D",
          points: 3,
          isQualified: false,
        },
      ],
    },

    {
      groupName: "Group 2",
      teams: [
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team E",
          points: 10,
          isQualified: true,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team F",
          points: 7,
          isQualified: false,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team G",
          points: 5,
          isQualified: false,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team H",
          points: 2,
          isQualified: false,
        },
      ],
    },

    {
      groupName: "Group 3",
      teams: [
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team I",
          points: 11,
          isQualified: true,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team J",
          points: 7,
          isQualified: false,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team K",
          points: 5,
          isQualified: false,
        },
        {
          id: "",
          logo: "https://d1i8kmyez1731n.cloudfront.net/product-images/IMAGE_28ff5035-3164-49d5-a18d-72d5ba7ef9b1.png",
          name: "Team L",
          points: 4,
          isQualified: false,
        },
      ],
    },
  ],
};
