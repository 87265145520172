import { ENV, apiEndpoints, baseUrl } from "config/apiEndpoints";
import { GET, POST } from "services";
import { HandleLogOut, getAccessToken } from "utils/Helper";
// import { TOURNAMENT_PAGE_SIZE } from "utils/constants";

// tournaments V3

export const uploadImagetoS3 = async (file: any) => {
  var headers = new Headers();
  headers.append("accept", "*/*");
  headers.append("authorization", `Bearer ${getAccessToken()}`);

  var formdata = new FormData();
  formdata.append("doc_type", "IMAGE");
  formdata.append("file", file);

  let response: any;

  try {
    response = await fetch(baseUrl[ENV] + apiEndpoints.uploadAsset, {
      method: "POST",
      headers: headers,
      body: formdata,
      redirect: "follow",
    });
  } catch (error) {
    console.log(error);
    return;
  }
  if (response?.status === 401) {
    HandleLogOut();
    return;
  }
  const resultText = await response?.text();
  const result = resultText === "" ? {} : JSON.parse(resultText);
  return result;
};

export const getTournamentsListPaginated = async (data: any) => {
  // if (!Object.keys(data).length) {
  //   data = {
  //     page: 1,
  //     pageSize: TOURNAMENT_PAGE_SIZE,
  //   };
  // }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.tournamentsPaginatedListNew,
    data
  );
  return result;
};

export const getTournamentConfig = async () => {
  let result = await GET(baseUrl[ENV] + apiEndpoints.getTournamentV3Config);
  return result;
};

export const getTournamentConfigById = async (
  tournamentId: string,
  query?: string,
  query2?: string
) => {
  const params: any = { tournamentId: tournamentId };

  if (query) {
    params["stepKey"] = query;
  }

  if (query2) {
    params["teamId"] = query2;
  }

  let result = await GET(
    baseUrl[ENV] + apiEndpoints.getTournamentV3Config,
    params
  );
  return result;
};

export const createTournament = async (data: any) => {
  let result = await POST(baseUrl[ENV] + apiEndpoints.createTournamentV3, data);
  return result;
};

export const getVenueListPaginated = async (reqData: any) => {
  if (!Object.keys(reqData).length) {
    reqData = {
      page: 1,
      pageSize: 50,
    };
  }
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.groundsPaginatedList,
    reqData
  );
  return result;
};

//  fetching teams by name for multi select dropdown ----- DEPRECATED (OLD VERSION)
export const getTeamsListByName = async (searchValue: string) => {
  const params = { name: searchValue };
  let result = await GET(baseUrl[ENV] + apiEndpoints.getTeamsByName, params);
  return result;
};

export const getCityListByName = async (searchValue: string) => {
  const params = { name: searchValue };
  let result = await GET(baseUrl[ENV] + apiEndpoints.getCityByName, params);
  return result;
};

//  fetching teams by name for multi select dropdown
export const getPaginatedTeamsListByName = async (reqData: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.teamsPaginatedList,
    reqData
  );

  return result;
};

//  fetching players by name for multi select dropdown
export const getPaginatedPlayersListByName = async (reqData: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.playersListPaginated,
    reqData
  );

  return result;
};

//  fetching grounds by name for multi select dropdown
export const getPaginatedGroundsListByName = async (reqData: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.groundsPaginatedList,
    reqData
  );

  return result;
};

export const createTeamByMandatoryDetails = async (data: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.createTeamByMandatoryDetails,
    data
  );
  return result;
};

export const createPlayerByMandatoryDetails = async (data: any) => {
  let result = await POST(
    baseUrl[ENV] + apiEndpoints.createPlayerByMandatoryDetails,
    data
  );
  return result;
};

export const getTournamentDetailsById = async (tournamentId: any) => {
  const params = { tournamentId: tournamentId };
  let result = await GET(
    baseUrl[ENV] + apiEndpoints.getTournamentDetailsById,
    params
  );
  return result;
};
