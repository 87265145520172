import React from "react";
import styles from "./style.module.scss";

import tempIcon from "assets/tempIcon.svg";

interface IProps {
  data: any;
}

const index = ({ data }: IProps) => {
  return (
    <div className={styles["tournament-info-container"]}>
      <div className={styles["heading"]}>Tournament Info</div>
      <div className={styles["meta-data-container"]}>
        <div className={styles["meta-data-wrapper"]}>
          {data?.map((item: any, index: number) => {
            return (
              <div
                key={item?.title + index}
                className={styles["meta-data-stats"]}
              >
                <div className={styles["stat-icon"]}>
                  <img src={item?.icon || tempIcon} alt="" />
                </div>

                <div className={styles["stat-name"]}>
                  <div className={styles["title"]}>{item?.title}</div>
                  <div className={styles["sub-title"]}>{item?.subTitle}</div>
                </div>

                <div className={styles["stat-value"]}>{item?.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default index;
