export const createTeamInputFields = [
  {
    inputKey: "name",
    widgetType: "inputText",
    widgetAttributes: {
      label: "Team Name",
      isEditable: true,
      isMandatory: true,
      details: {
        maxLength: "",
        hint: "Team Name",
        value: "",
      },
    },
  },

  {
    inputKey: "short_name",
    widgetType: "inputText",
    widgetAttributes: {
      label: "Short Name",
      isEditable: true,
      isMandatory: true,
      details: {
        maxLength: "",
        hint: "Micro Name",
        value: "",
      },
    },
  },

  {
    inputKey: "compact_name",
    widgetType: "inputText",
    widgetAttributes: {
      label: "Micro Name",
      isEditable: true,
      isMandatory: true,
      details: {
        maxLength: "",
        hint: "Micro Name",
        value: "",
      },
    },
  },

  {
    inputKey: "image_url",
    widgetType: "imgUpload",
    widgetAttributes: {
      label: "Team Logo",
      isEditable: true,
      isMandatory: false,
      details: {
        hint: "Resolution expectation",
        imageFormat: ["JPEG", "PNG", "JPG"],
        maxSize: "5",
        value: "",
      },
    },
  },
];
