/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useState } from "react";

// components
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";
import MenuBar from "components/Menubar";
import Lister from "./Lister";
import ToastModal from "components/ToastModal";
import { toast } from "react-toastify";

// services
import { getTournamentsListPaginated } from "services/tournamentsV3";

// styles
import styles from "./style.module.scss";

const index = ({ tournamentId }: any) => {
  const [tournamentDetails, setTournamentDetails] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showToastModal, setShowToastModal] = useState(false);

  const [reqData, setReqData] = useState({
    page: 1,
    pageSize: 20,
    filter: {},
    sortby: {
      key: "createdAt",
      type: "DESC",
    },
  });

  const getTournementList = async (reqData: any) => {
    setLoading(true);
    let result = await getTournamentsListPaginated(reqData);
    if (result && !result?.isError) {
      setTournamentDetails(result?.data);
      setLoading(false);
      return;
    }
    //
    else {
      if (result?.reason === "TIMEOUT_ERROR") {
        toast.error(result?.errorMessage);
        return;
      }
      //
      else {
        setShowToastModal(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getTournementList(reqData);
  }, [reqData]);

  if (!tournamentDetails) {
    return (
      <ToastModal
        isModalOpen={showToastModal}
        setIsModalOpen={setShowToastModal}
        message={"Could not load the data. Please try again later."}
        navigationLink={"/"}
        variant={"fetch-failure"}
      />
    );
  }

  if (loading) {
    return <BackdropLoader loaderState={loading} />;
  }

  return (
    <div className={styles["outer-list-container"]}>
      <MenuBar />
      <div className={styles["main-list-container"]}>
        <Lister
          tournamentId={tournamentId}
          tournamentDetails={tournamentDetails}
          reqData={reqData}
          setReqData={setReqData}
        />
      </div>
    </div>
  );
};

export default index;
