/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

// components
import Hero from "./Hero";
import About from "./About";
import EntityScroller from "./EntityScoller";
import Matches from "./Matches";

import TournamentInfo from "./TournamentInfo";
import Gallery from "./Gallery";
import PointsTable from "./PointsTable";

import TournamentActions from "./TournamentActions";

// assets
import backButton from "assets/back-button.svg";
import AddEditButton from "components/AddEditButton";

interface indexProps {
  tournamentId: string | undefined;
  data: any;
}

const index = ({ tournamentId, data }: indexProps) => {
  const navigate = useNavigate();

  const handleBackPress = () => {
    navigate("/tournament-v3");
  };

  return (
    <div className={styles["preview-container"]}>
      <div className={styles["middle-section-container"]}>
        <div className={styles["page-heading"]}>
          <img
            src={backButton}
            alt=""
            width={15}
            height={15}
            onClick={handleBackPress}
            style={{ cursor: "pointer" }}
          />
          {data?.tournamentDetails?.name}
          <AddEditButton text="Edit" editProperty="" />
        </div>
        <div className={styles["middle-section-wrapper"]}>
          <Hero data={data?.tournamentDetails} />
          <About data={data?.about} />
          <EntityScroller
            heading="Teams"
            entities={data?.teams}
            editQuery="Groups"
            tournamentId={tournamentId}
          />
          <EntityScroller
            heading="Sponsors"
            entities={data?.sponsors}
            editQuery="Sponsors"
            tournamentId={tournamentId}
          />
          <Matches data={data?.matches} tournamentId={tournamentId} />
        </div>
      </div>

      <div className={styles["right-section-container"]}>
        <div className={styles["right-section-wrapper"]}>
          <TournamentInfo data={data?.metaData} />
          <PointsTable />
          <Gallery data={data?.videos} />
        </div>
      </div>

      <div className={styles["tournament-actions"]}>
        <TournamentActions
          tournamentId={tournamentId}
          isActive={data?.tournamentDetails?.isActive}
          tournamentStatus={data?.tournamentDetails?.status}
        />
      </div>
    </div>
  );
};

export default index;
