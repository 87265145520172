import React from "react";
import styles from "./style.module.scss";

const index = ({ text }: any) => {
  return (
    <div className={styles["no-entity"]}>Your {text} will appear here!</div>
  );
};

export default index;
