import React from "react";
import { Controller } from "react-hook-form";

import TextInput from "components/InputComponentsMultiStep/Text";
import NumberInput from "components/InputComponentsMultiStep/Number";
import RadioSelect from "components/InputComponentsMultiStep/RadioSelect";
import SingleSelectDropdown from "components/InputComponentsMultiStep/SingleSelectDropdown";
import ImageUpload from "components/InputComponentsMultiStep/ImageUpload";
import SearchableMultiSelectDropdown from "components/InputComponentsMultiStep/SearchableMultiSelectDropdown";
import FormMultiselectLive from "components/InputComponentsMultiStep/MultiSelectLive";
// import CustomInput from "./index";

import {
  getPaginatedTeamsListMapped,
  getPaginatedPlayersListMapped,
  getPaginatedVenueListMapped,
  getCityByName,
} from "components/TournamentV3/helper";

const getSearchableOptions = (dropdownOptions: any, inputKey: string) => {
  switch (inputKey) {
    case "venue":
      return dropdownOptions.venue;
    default:
      return dropdownOptions.venue;
  }
};

const optionsMapper: any = {
  team: getPaginatedTeamsListMapped,
  player: getPaginatedPlayersListMapped,
  venue: getPaginatedVenueListMapped,
  city: getCityByName,
};

export const RenderFieldCustom = (
  field: any,
  entryIndex: number,
  inputKey: any,
  values: any,
  setValues: any,
  dropdownOptions: any,
  unregister: any,
  control?: any,
  formType?: string,
  editAllowed?: boolean,
  setAdditionalEntity?: any,
  newTeam?: any
) => {
  const fieldName = `${inputKey}[${entryIndex}].${field.inputKey}`; // Unique name for each entry

  return (
    <Controller
      key={fieldName}
      name={fieldName}
      control={control}
      defaultValue={values[entryIndex]?.[field.inputKey] || ""}
      rules={{
        required: field.widgetAttributes.validations?.isMandatory
          ? "This field is required"
          : false,
      }}
      render={({ field: controllerField }) => {
        const commonProps = {
          ...controllerField,
          label: field.widgetAttributes.label,
          placeholder: field?.widgetAttributes?.details?.hint,
          isRequired: field?.widgetAttributes?.isMandatory,
          isEditable: field?.widgetAttributes?.isEditable && editAllowed,
          inputKey: field.inputKey,
          presentValue:
            values[entryIndex]?.[field.inputKey] ||
            field?.widgetAttributes?.details?.value,
          onInputChange: (value: any) => {
            controllerField.onChange(value);
            setValues((prevValues: any) => {
              const updatedValues = [...prevValues];
              updatedValues[entryIndex] = {
                ...updatedValues[entryIndex],
                [field.inputKey]: value,
              };
              return updatedValues;
            });
          },
        };

        switch (field.widgetType) {
          case "inputText":
            return (
              <TextInput
                {...commonProps}
                maxLength={field?.widgetAttributes?.details?.maxLength}
                isFullWidth={false}
              />
            );

          case "inputInt":
            return (
              <NumberInput // handle change done
                {...commonProps}
                maxLength={field?.widgetAttributes?.details?.maxValue}
                // isFullWidth={false}
              />
            );

          case "singleSelect":
            if (field?.widgetAttributes?.details?.options?.length <= 2) {
              return (
                <RadioSelect
                  {...commonProps}
                  options={field?.widgetAttributes?.details?.options}
                  isFullWidth={false}
                />
              );
            } else {
              return (
                <SingleSelectDropdown // handle change done
                  {...commonProps}
                  options={field?.widgetAttributes?.details?.options}
                  isFullWidth={false}
                />
              );
            }

          case "imgUpload":
            return (
              <ImageUpload
                {...commonProps}
                isFullWidth={false}
                imageWidth={field?.widgetAttributes?.details?.width}
              />
            );

          case "multiSelectSearch":
            return (
              <SearchableMultiSelectDropdown
                {...commonProps}
                options={getSearchableOptions(dropdownOptions, field.inputKey)}
                isFullWidth={false}
              />
            );

          case "multiSelectLive":
            return (
              <FormMultiselectLive
                {...commonProps}
                name={field.inputKey}
                optionsFetcher={
                  optionsMapper[field?.widgetAttributes?.details?.entity]
                }
                idParam="id"
                populateOptions={true}
                isFullWidth={false}
                isMultiSelectAllowed={
                  field?.widgetAttributes?.details?.multiSelectAllowed
                }
                setAdditionalEntity={setAdditionalEntity}
                searchableEntity={field?.widgetAttributes?.details?.entity}
                newTeam={newTeam}
              />
            );

          // case "custom":
          //   return (
          //     <CustomInput
          //       {...commonProps}
          //       componentArray={field.componentArray}
          //       isExtendable={field.isExtendable}
          //       dropdownOptions={dropdownOptions}
          //       control={control}
          //       unregister={unregister}
          //       formType={formType}
          //     />
          //   );

          default:
            return <div>Unknown widget type: {field.widgetType}</div>;
        }
      }}
    />
  );
};
