import { globalNavigate } from "GlobalHistory";
import { format } from "date-fns";

export const setRefreshToken = (token: string) => {
  localStorage.setItem("refresh_token", token);
};

export const setAccessToken = (token: string) => {
  localStorage.setItem("access_token", token);
};

export const setUserDetails = (data: string) => {
  localStorage.setItem("user_details", data);
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token") || "";
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token") || "";
};

export const getUserDetails = () => {
  return localStorage.getItem("user_details");
};

export const getAdminId = () => {
  let userDetails: any = localStorage.getItem("user_details");
  if (userDetails) {
    let data = JSON.parse(userDetails);
    return data?.user_id;
  }
  return null;
};

export const getAdminName = () => {
  let userDetails: any = localStorage.getItem("user_details");
  if (userDetails) {
    let data = JSON.parse(userDetails);
    return data?.name;
  }
  return "";
};

export const HandleLogOut = () => {
  if (typeof window !== "undefined") {
    let storageL = localStorage;

    storageL.removeItem("access_token");
    storageL.removeItem("refresh_token");
    storageL.removeItem("user_details");
    globalNavigate("/login");
  }
};

export const getDate = (timestamp: any) => {
  const date = new Date(timestamp);
  return format(date, "d MMM. yyyy");
};

export const getTime = (timestamp: any) => {
  const date = new Date(timestamp);
  return format(date, "hh:mm a").toLowerCase(); // Lowercase the AM/PM
};
