/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./style.module.scss";

interface indexProps {
  text: string;
  editProperty: string;
  navigationLink?: string;
}

const index = ({ text, editProperty, navigationLink }: indexProps) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const handleClick = (query: string) => {
    if (navigationLink) {
      navigate(navigationLink);
      return;
    }
    const data = {
      query: query,
    };

    navigate(`/tournament-v3/edit/${id}`, {
      state: { message: data },
    });
  };

  return (
    <button
      className={styles["add-edit-button"]}
      onClick={() => {
        handleClick(editProperty);
      }}
    >
      {text}
    </button>
  );
};

export default index;
