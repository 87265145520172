/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate } from "react-router-dom";

// components
import StatusChip from "components/StatusChip";
import ShowHide from "./ShowHide";
import NoEntity from "components/TournamentV3/View/Preview/NoEntity";

// utils
import { sportMapper } from "utils/constants";
import { getDate, getTime } from "utils/Helper";

// styles
import styles from "./style.module.scss";

// assets
import chevronRight from "assets/Tournament/chevronLeft.svg";
import chevronLeft from "assets/Tournament/chevronRight.svg";

interface IProps {
  tournamentDetails: any;
  setReqData: any;
}

const index = ({ tournamentDetails, setReqData }: IProps) => {
  const navigate = useNavigate();

  const isPrevPageAvailable = tournamentDetails?.pagination?.page > 1;
  const isNextPageAvailable = tournamentDetails?.pagination?.hasMore;
  const currentPage = !tournamentDetails?.pagination?.totalPages
    ? 0
    : tournamentDetails?.pagination?.page;
  const totalPages = tournamentDetails?.pagination?.totalPages;

  const getBgColor = (index: number) => {
    if (index % 2 === 0) {
      return "rgba(0, 0, 0, 0.22)";
    } else {
      return "rgba(255, 255, 255, 0.04)";
    }
  };

  const handlePrevPage = () => {
    if (isPrevPageAvailable) {
      setReqData((prev: any) => {
        return {
          ...prev,
          page: prev.page - 1,
        };
      });
    } else {
      return;
    }
  };

  const handleNextPage = () => {
    if (tournamentDetails?.pagination?.hasMore) {
      setReqData((prev: any) => {
        return {
          ...prev,
          page: prev.page + 1,
        };
      });
    } else {
      return;
    }
  };

  const handleTournamentClick = (tournamentId: string) => {
    navigate(`/tournament-v3/view/${tournamentId}`);
  };

  return (
    <>
      <div className={styles["listing-table"]}>
        <div className={styles["listing-table-head"]}>
          <div className={styles["tournament-name"]}>Tournament Name</div>
          <div className={styles["created-date"]}>Created Date</div>
          <div className={styles["start-date"]}>Start Date</div>
          <div className={styles["host-city"]}>Host City</div>
          <div className={styles["sports"]}>Sports</div>
          <div className={styles["status"]}>Status</div>
          <div className={styles["show-hide"]}>Show / Hide</div>
        </div>

        <div className={styles["listing-table-body"]}>
          {tournamentDetails?.list?.length ? (
            tournamentDetails?.list?.map((item: any, index: number) => {
              return (
                <div key={index} className={styles["body-row"]}>
                  <div
                    className={styles["row-tournament-name"]}
                    onClick={() => handleTournamentClick(item?.tournamentId)}
                  >
                    <div className={styles["sno"]}>{index + 1}</div>
                    <div className={styles["name"]}>{item?.name}</div>
                  </div>
                  <div
                    className={styles["row-created-date"]}
                    style={{ backgroundColor: getBgColor(index) }}
                  >
                    <div className={styles["date"]}>
                      {getDate(item?.createdAt)}
                    </div>
                    <div className={styles["time"]}>
                      {getTime(item?.createdAt)}
                    </div>
                  </div>
                  <div
                    className={styles["row-start-date"]}
                    style={{ backgroundColor: getBgColor(index) }}
                  >
                    {getDate(item?.startDate)}
                  </div>
                  <div
                    className={styles["row-host-city"]}
                    style={{ backgroundColor: getBgColor(index) }}
                  >
                    {item?.hostCity}
                  </div>
                  <div
                    className={styles["row-sports"]}
                    style={{ backgroundColor: getBgColor(index) }}
                  >
                    {sportMapper[item?.sports]}
                  </div>
                  <div
                    className={styles["row-status"]}
                    style={{ backgroundColor: getBgColor(index) }}
                  >
                    {item?.status ? (
                      <StatusChip
                        text={item?.status}
                        extStyles={{ width: "max-content" }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <ShowHide item={item} index={index} />
                </div>
              );
            })
          ) : (
            <NoEntity text={"Tournaments"} />
          )}
        </div>

        {tournamentDetails?.list?.length ? (
          <div className={styles["pagination-container"]}>
            <div className={styles["pagination-text"]}>
              Showing {currentPage} of {totalPages} Pages
            </div>
            <div className={styles["pagination-buttons"]}>
              <div
                className={styles["next-button"]}
                style={{
                  opacity: isPrevPageAvailable ? 1 : 0.5,
                  cursor: isPrevPageAvailable ? "pointer" : "not-allowed",
                }}
                onClick={handlePrevPage}
              >
                <img src={chevronLeft} alt="chevron-left" />
              </div>

              <div
                className={styles["prev-button"]}
                style={{
                  opacity: isNextPageAvailable ? 1 : 0.5,
                  cursor: isNextPageAvailable ? "pointer" : "not-allowed",
                }}
                onClick={handleNextPage}
              >
                <img src={chevronRight} alt="chevron-Right" />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default index;
