/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

// styles
import styles from "./style.module.scss";

// services
import { createTournament } from "services/tournamentsV3";

// assets
import threeDots from "assets/threeDots.svg";
import calendar from "assets/Tournament/CalendarX.svg";
import eyeClosed from "assets/Tournament/EyeClosed.svg";
import trophy from "assets/Tournament/Trophy.svg";
// import copy from "assets/Tournament/copy.svg";
import eyeOpenBlue from "assets/Tournament/eyeOpenBlue.svg";
import eyeClosedBlue from "assets/Tournament/eyeClosedBlue.svg";
import end from "assets/Tournament/end.svg";

interface IProps {
  tournamentId: string | undefined;
  isActive: any;
  tournamentStatus: string;
}

const index = ({ tournamentId, isActive, tournamentStatus }: IProps) => {
  const navigate = useNavigate();

  const [isEyeOpen, setIsEyeOpen] = useState(isActive);
  const [loading, setLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const editTournament = async (formData: any) => {
    setLoading(true);
    let result = await createTournament(formData);
    if (result && !result.isError) {
      setIsEyeOpen(!isEyeOpen);
      setLoading(false);
    } else {
      if (result?.reason === "TIMEOUT_ERROR") {
        toast.error(result?.errorMessage);
        return;
      }
      toast.error("Something went wrong,  Please try again later.");
      setLoading(false);
    }
  };

  const handleHideClick = () => {
    const formData = {
      isActive: isEyeOpen ? "False" : "True",
      tournamentId: tournamentId,
    };

    editTournament(formData);
  };

  const handleClick = (query: string) => {
    const data = {
      query: query,
    };

    navigate(`/tournament-v3/edit/${tournamentId}`, {
      state: { message: data },
    });
  };

  return (
    <div className={styles["dropdown-container"]} ref={dropdownRef}>
      <img
        className={styles["three-dot-icon"]}
        src={threeDots}
        alt="icon"
        onClick={toggleDropdown}
      />

      {isDropdownVisible && (
        <div className={styles["dropdown-menu"]}>
          <div className={styles["dropdown-option"]}>
            <div className={styles["action-logo-name"]}>
              <div className={styles["action-logo"]}>
                <img src={trophy} alt="trophy" />
              </div>
              <div className={styles["action-name"]}>View Tournament</div>
            </div>
            <div className={styles["action-icon"]}>
              <img src={end} alt="" />
            </div>
          </div>

          <div className={styles["dropdown-option"]} onClick={handleHideClick}>
            <div className={styles["action-logo-name"]}>
              <div className={styles["action-logo"]}>
                <img src={eyeClosed} alt="trophy" />
              </div>
              <div className={styles["action-name"]}>
                {`${isEyeOpen ? "Hide" : "Show"}`} Tournament
              </div>
            </div>
            {loading ? (
              <Oval
                visible={true}
                height="20"
                width="20"
                color="#3CA6FF"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
                secondaryColor={"#3CA6FF"}
              />
            ) : (
              <div className={styles["action-icon"]}>
                <img src={isEyeOpen ? eyeOpenBlue : eyeClosedBlue} alt="" />
              </div>
            )}
          </div>

          <div
            className={styles["dropdown-option"]}
            onClick={() => {
              handleClick("Awards");
            }}
          >
            <div className={styles["action-logo-name"]}>
              <div className={styles["action-logo"]}>
                <img src={calendar} alt="trophy" />
              </div>
              <div className={styles["action-name"]}>
                {tournamentStatus === "RESULT" ? "Awards" : "End Tournament"}
                {/* End Tournament */}
              </div>
            </div>
            <div className={styles["action-icon"]}>
              <img src={end} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default index;
