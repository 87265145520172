import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import addMore from "assets/addMore.svg";
import { RenderFieldCustom } from "./RenderFieldCustom";
import ToastModal from "components/ToastModal";

const CustomInput = ({
  inputKey,
  label,
  componentArray,
  isExtendable,
  control,
  isEditable,
  onInputChange,
  dropdownOptions,
  unregister,
  formType,
  presentValue = [],
  warningLabel,
  setIsSubmitAllowed,
  setAdditionalEntity,
  newTeam,
}: any) => {
  // Ensure 'presentValue' is always an array
  const [values, setValues] = useState(
    Array.isArray(presentValue) ? presentValue : []
  );
  const [editingIndex, setEditingIndex] = useState<number | null>(null); // Track current editing entry
  const [savedEntries, setSavedEntries] = useState<boolean[]>(
    Array(values.length).fill(true)
  );

  const [successToast, setSuccessToast] = useState(false);

  const addNewEntry = () => {
    if (!isEditable || editingIndex !== null) return; // Prevent adding while editing another entry
    setValues((prevValues) => [...prevValues, {}]);
    setSavedEntries((prev) => [...prev, false]); // New entry is not saved initially
    setEditingIndex(values.length); // Set the new entry as editing
  };

  const removeEntry = (index: number) => {
    if (!isEditable) return;

    // Unregister all fields related to this entry index
    componentArray.forEach((field: any) => {
      const fieldName = `${inputKey}[${index}].${field.inputKey}`;
      unregister(fieldName, { keepDirty: false }); // Ensure it doesn't retain values
    });

    setValues((prevValues) => prevValues.filter((_, i) => i !== index));
    setSavedEntries((prev) => prev.filter((_, i) => i !== index));
    if (editingIndex === index) {
      setEditingIndex(null);
    } else if (editingIndex !== null && editingIndex > index) {
      setEditingIndex(editingIndex - 1);
    }
  };

  const handleSave = (index: number) => {
    // Validate mandatory fields
    const isValid = componentArray.every(
      (field: any) =>
        !field?.widgetAttributes?.isMandatory ||
        !!values[index]?.[field.inputKey]
    );

    if (!isValid) {
      // alert("Please fill all mandatory fields before saving.");
      setSuccessToast(true);
      return;
    }

    const newSavedEntries = [...savedEntries];
    newSavedEntries[index] = true;
    setSavedEntries(newSavedEntries);
    setEditingIndex(null); // Stop editing this entry
  };

  const handleEdit = (index: number) => {
    if (editingIndex === null) {
      setEditingIndex(index); // Set the entry to edit mode
      setSavedEntries((prev) => {
        const newSavedEntries = [...prev];
        newSavedEntries[index] = false; // Mark as unsaved
        return newSavedEntries;
      });
    } else return;
  };

  useEffect(() => {
    //  if presentvalue is empty, add a new entry
    if (!presentValue || presentValue.length === 0) {
      addNewEntry();
    }
  }, []);

  useEffect(() => {
    onInputChange(values);
  }, [values]);

  useEffect(() => {
    // If the user is trying to submit the form, check if all entries are saved
    const isAllSaved = savedEntries.every((entry) => entry);
    setIsSubmitAllowed(isAllSaved);
  }, [savedEntries]);

  return (
    <>
      <div className={styles["custom-input-container"]}>
        {values.map((entry, index) => (
          <>
            <div
              className={styles["custom-input-wrapper"]}
              style={{
                border: editingIndex === index ? "1px solid #CAF906" : "",
              }}
            >
              {/* <div className={styles["sequence-header"]}>
              <div className={styles["sequence-number"]}>{`${label} ${
                index + 1
              }`}</div>
            </div> */}
              <div key={index} className={styles["custom-input-entry"]}>
                {componentArray.map((field: any) => (
                  <>
                    {RenderFieldCustom(
                      field,
                      index,
                      inputKey,
                      values,
                      setValues,
                      dropdownOptions,
                      unregister,
                      control,
                      formType,
                      editingIndex === index,
                      setAdditionalEntity,
                      newTeam
                    )}
                  </>
                ))}
              </div>
              <div className={styles["save-delete-buttons"]}>
                {warningLabel && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className={styles["warning-message"]}
                  >
                    {warningLabel}
                  </div>
                )}
                {editingIndex === index ? (
                  <div
                    className={styles["save"]}
                    onClick={() => handleSave(index)}
                  >
                    Save
                  </div>
                ) : (
                  <div
                    className={styles["edit"]}
                    onClick={() => {
                      handleEdit(index);
                    }}
                  >
                    Edit
                  </div>
                )}

                <div
                  className={styles["delete"]}
                  onClick={() => removeEntry(index)}
                >
                  Delete
                </div>
              </div>
            </div>
          </>
        ))}

        {isExtendable && (
          <div className={styles["add-entry-wrapper"]}>
            <div
              onClick={addNewEntry}
              className={styles["add-entry-button"]}
              style={{
                border:
                  editingIndex === null
                    ? "1px solid rgba(255, 255, 255, 0.9)"
                    : "1px solid rgba(255, 255, 255, 0.2)",
              }}
            >
              {`Add ${label}`}{" "}
              <span>
                <img src={addMore} alt="add-more" />
              </span>
            </div>
          </div>
        )}
      </div>

      <ToastModal
        isModalOpen={successToast}
        setIsModalOpen={setSuccessToast}
        message={"Please fill all mandatory fields before saving."}
        navigationLink={""}
        variant={"submit-success"}
      />
    </>
  );
};

export default CustomInput;
