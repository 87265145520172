import {
  getTeamsListByName,
  getPaginatedTeamsListByName,
  getPaginatedPlayersListByName,
  getPaginatedGroundsListByName,
  getCityListByName,
} from "services/tournamentsV3";

export const getPaginatedTeamsListMapped = async (searchVal: string) => {
  const reqData = {
    page: 1,
    pageSize: 100,
    filter: {
      name: searchVal,
    },
  };

  let result = await getPaginatedTeamsListByName(reqData);

  if (result && !result?.isError) {
    const list = result?.data?.list?.map((team: any) => {
      return {
        id: team?.team_id,
        phone: "",
        label: team?.name,
        location: team?.team_city,
        image: team?.image_url,
      };
    });

    const data = {
      currentPage: result?.data?.pagination?.page,
      hasMore: result?.data?.pagination?.hasMore,
      list: list,
    };

    return data;
  }

  return [];
};

export const getTeamsListMapped = async (searchVal: string) => {
  let result = await getTeamsListByName(searchVal);
  if (result && !result?.isError) {
    return result?.data?.map((team: any) => {
      return {
        id: team?.team_id,
        label: team?.name,
        location: team?.team_city,
        image: team?.image_url,
      };
    });
  }
  return [];
};

export const getPaginatedPlayersListMapped = async (searchVal: string) => {
  const reqData = {
    page: 1,
    pageSize: 100,
    filter: {
      name: searchVal,
    },
  };

  let result = await getPaginatedPlayersListByName(reqData);

  if (result && !result?.isError) {
    const list = result?.data?.list?.map((player: any) => {
      return {
        id: player?.player_id,
        phone: player?.mobile,
        label: player?.name,
        location: player?.city,
        image: player?.image_url,
      };
    });

    const data = {
      currentPage: result?.data?.pagination?.page,
      hasMore: result?.data?.pagination?.hasMore,
      list: list,
    };

    return data;
  }

  return [];
};

export const getPaginatedVenueListMapped = async (searchVal: string) => {
  const reqData = {
    page: 1,
    pageSize: 100,
    filter: {
      name: searchVal,
    },
  };

  let result = await getPaginatedGroundsListByName(reqData);

  if (result && !result?.isError) {
    const list = result?.data?.list?.map((venue: any) => {
      return {
        id: venue?.venueId,
        phone: venue?.ownerContact,
        label: venue?.name,
        location: venue?.city,
        image: venue?.imageUrl,
      };
    });

    const data = {
      currentPage: result?.data?.pagination?.page,
      hasMore: result?.data?.pagination?.hasMore,
      list: list,
    };

    return data;
  }

  return [];
};

export const getCityByName = async (searchVal: string) => {
  let result = await getCityListByName(searchVal);

  if (result && !result?.isError) {
    const list = result?.data?.map((city: any) => {
      return {
        id: city?.cityId,
        label: city?.name,
        location: city?.city,
      };
    });

    const data = {
      list: list,
    };

    return data;
  }

  return [];
};
