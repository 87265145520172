export const sideDrawerWidth = 220;

export const TOURNAMENT_PAGE_SIZE = 20;
export const MATCH_PAGE_SIZE = 20;
export const TEAM_PAGE_SIZE = 20;
export const SCORER_PAGE_SIZE = 20;
export const GROUND_PAGE_SIZE = 20;
export const PLAYER_PAGE_SIZE = 20;
export const BULK_UPLOAD_CHUNK_SIZE = 250;

export const STATUS_LIST = {
  ONGOING: "ONGOING",
  UPCOMING: "UPCOMING",
  COMPLETED: "COMPLETED",
  PAUSED: "PAUSED",
  ABONDENED: "ABANDONED",
  CANCELLED: "CANCELLED",
  SCHEDULED: "SCHEDULED",
  RESULT: "RESULT",
};

export const matchAttributesList: any = [
  "no_of_overs",
  "max_overs_per_bowler",
  "super_over",
  "match_category",
  "match_name",
  "match_number",
  "sequence_number",
  "balls_per_over",
  "overs_per_skin",
  "skins_per_inning",
];

export const matchAttributesMap: any = {
  maxOversPerBowler: "max_overs_per_bowler",
  noOfOvers: "no_of_overs",
  superOver: "super_over",

  matchcategory: "match_category",
  matchname: "match_name",
  matchnumber: "match_number",
  sequencenumber: "sequence_number",

  match_category: "match_category",
  match_name: "match_name",
  match_number: "match_number",
  sequence_number: "sequence_number",

  matchCategory: "match_category",
  matchName: "match_name",
  matchNumber: "match_number",
  sequenceNumber: "sequence_number",

  ballsPerOver: "balls_per_over",
  oversPerSkin: "overs_per_skin",
  skinsPerInning: "skins_per_inning",
};

export const sportMapper: any = {
  INDOOR_CRICKET: "Indoor Cricket",
};
