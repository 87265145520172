import React from "react";
import styles from "./style.module.scss";

import TeamStrip from "./TeamStrip";

import { contract } from "../../contract";

const index = () => {
  const { pointsTable } = contract;
  return (
    <div className={styles["pt-container"]}>
      <div className={styles["heading"]}>Points</div>
      <div className={styles["groups-container"]}>
        <div className={styles["pt-headers"]}>
          <div className={styles["header-teams"]}>Teams</div>
          <div className={styles["header-points"]}>Pts</div>
          <div className={styles["header-qualified"]}>Qualified</div>
        </div>

        {pointsTable?.map((group: any, index: number) => {
          return (
            <div key={group?.name + index} className={styles["group-wrapper"]}>
              <div className={styles["group-name"]}>{group?.groupName}</div>
              <div className={styles["teams-container"]}>
                {group?.teams?.map((team: any, index: number) => {
                  return (
                    <TeamStrip
                      key={team?.id + index}
                      team={team}
                      index={index}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default index;
