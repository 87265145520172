export const createPlayerInputFields = [
  {
    inputKey: "name",
    widgetType: "inputText",
    widgetAttributes: {
      label: "Player Name",
      isEditable: true,
      isMandatory: true,
      details: {
        maxLength: "",
        hint: "Player Name",
        value: "",
      },
    },
  },

  {
    inputKey: "gender",
    widgetType: "singleSelect",
    widgetAttributes: {
      label: "Tournament Format",
      isEditable: true,
      isMandatory: true,
      details: {
        hint: "",
        options: ["Male", "Female"],
        value: "",
      },
    },
  },

  {
    inputKey: "mobile",
    widgetType: "inputInt",
    widgetAttributes: {
      label: "Phone Number",
      isEditable: true,
      isMandatory: true,
      details: {
        maxValue: 99999999999,
        hint: "Phone Number",
        value: "",
      },
    },
  },

  {
    inputKey: "city",
    widgetType: "multiSelectLive",
    widgetAttributes: {
      label: "City",
      isEditable: true,
      isMandatory: false,
      details: {
        hint: "Select City",
        multiSelectAllowed: false,
        value: [],
        entity: "city",
      },
    },
  },

  {
    inputKey: "imgUrl",
    widgetType: "imgUpload",
    widgetAttributes: {
      label: "Player Image (Max 5MB)",
      isEditable: true,
      isMandatory: false,
      details: {
        hint: "Resolution expectation",
        imageFormat: ["JPEG", "PNG", "JPG"],
        maxSize: "5",
        value: "",
      },
    },
  },
];
