import React from "react";
import styles from "./style.module.scss";
import VideoCard from "./VideoCard";

import NoEntity from "../NoEntity";

import AddEditButton from "components/AddEditButton";

const index = ({ data }: any) => {
  return (
    <div className={styles["gallery-container"]}>
      <div className={styles["heading"]}>
        Videos
        <span>
          <AddEditButton text="Add / Edit" editProperty="Videos" />
        </span>
      </div>
      <div className={styles["videos-container"]}>
        <div className={styles["videos-wrapper"]}>
          {data?.length ? (
            data?.map((item: any, index: number) => {
              return <VideoCard key={item?.title + index} data={item} />;
            })
          ) : (
            <NoEntity text={"Highlights"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default index;
