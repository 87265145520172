import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";

interface TextInputProps {
  maxLength: number;
  inputKey: string;
  isRequired: boolean;
  label: string;
  placeholder: string;
  presentValue: string;
  isEditable?: boolean;
  isFullWidth?: boolean;
  onInputChange: any;
  errors?: any;
}

const TextInput = ({
  maxLength,
  inputKey,
  isRequired,
  label,
  placeholder,
  presentValue,
  isEditable,
  isFullWidth,
  onInputChange,
  errors,
}: TextInputProps) => {
  const [value, setValue] = useState(presentValue || "");

  useEffect(() => {
    // Update the input value when presentValue changes (e.g., when editing)
    setValue(presentValue || "");
  }, [presentValue]);

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setValue(newValue); // Update internal state
    onInputChange(newValue); // Call the onInputChange function with new value
  };

  return (
    <div
      className={styles["text-input-container"]}
      style={{ width: isFullWidth ? "100%" : "" }}
    >
      <label htmlFor={inputKey}>
        {label}
        {isRequired && <span style={{ color: "red" }}> *</span>}
      </label>
      <input
        type="text"
        id={inputKey}
        placeholder={placeholder}
        maxLength={maxLength}
        required={isRequired}
        value={value}
        onChange={handleChange}
        disabled={!isEditable}
        style={{
          border: errors?.type === "required" ? "1px solid #FF523F" : "",
        }}
      />
    </div>
  );
};

export default TextInput;
