/*eslint-disable*/
import React, { useEffect, useId, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import { debounce } from "@mui/material/utils";

import {
  MultiSearchDropdownStyles,
  SelectFieldStyles,
} from "components/Common Components/Form Fields/FormUtils/FormStyles";

import EntityDropDown from "components/InputComponentsMultiStep/Dropdowns/EntityDropdown";

import styles from "./style.module.scss";
import Icon from "assets/Search.svg";

const IconPop = () => {
  return <img src={Icon} style={{ marginTop: "0.3rem" }} />;
};

const FormMultiselectLive = ({
  name, //done
  label, //done
  placeholder, //done
  isEditable, //done
  isRequired, //done
  presentValue, //done
  optionsFetcher, // done
  OptionsDisplayComp = EntityDropDown, // component to display options
  populateOptions, //done
  onInputChange, // done
  isFullWidth,
  idParam,
  isMultiSelectAllowed,
  setAdditionalEntity,
  searchableEntity,
  newTeam,
}: any) => {
  const [data, setData] = useState<any>([]);
  const [inputVal, setInputVal] = useState("");
  const [currentValues, setCurrentValues] = useState<any>(presentValue);
  const componentId = useId();

  const fetchOptions = React.useMemo(
    () =>
      debounce(async (input) => {
        if (optionsFetcher) {
          let result = await optionsFetcher(input);
          if (result) setData(result?.list);
        } else setData([]);
      }, 400),
    []
  );

  useEffect(() => {
    if (populateOptions) fetchOptions("");
    if (inputVal.length >= 3) fetchOptions(inputVal);
  }, [inputVal, fetchOptions]);

  const getAllOption = (data: any) => {
    if (!isMultiSelectAllowed) {
      if (data?.length) {
        return data;
      }
      //
      else {
        return [
          {
            label: "No options found",
            type: "not-found",
          },
        ];
      }
    }
    //
    else if (data?.length) {
      return [
        ...data,
        {
          label: searchableEntity === "team" ? "Create Team" : "Create Player",
          type: "create-button",
        },
      ];
    }
    //
    else if (!data?.length) {
      return [
        {
          label: "No options found",
          type: "not-found",
        },
        {
          label: searchableEntity === "team" ? "Create Team" : "Create Player",
          type: "create-button",
        },
      ];
    }
  };

  const optionsWithCreateButton = getAllOption(data);

  const handleCreate = () => {
    setAdditionalEntity([searchableEntity, componentId]);
  };

  useEffect(() => {
    if (newTeam && newTeam?.componentId === componentId) {
      // update the current value with new team
      setCurrentValues((prev: any) => [...prev, newTeam.data]);
    }
  }, [newTeam]);

  const handleOptionChange = (_: any, values: any) => {
    setCurrentValues(values);
  };

  useEffect(() => {
    onInputChange(currentValues);
  }, [currentValues]);

  const boxStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#2F2F2F",
    height: "66px",
    paddingLeft: "16px",
  };

  const noOptionsStyle = {
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    fontfamily: "Lato",
  };

  const createButtonStyle = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "6px",
    padding: "10px 16px",
    borderRadius: "200px",
    border: "1px solid #515151",
    background: "rgba(255, 255, 255, 0.08)",
    boxshadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    textalign: "center",
    fontfamily: "Lato",
    fontSize: "14px",
    fontWeight: "700",
    letterSpacing: "0.32px",
    cursor: "pointer",
  };

  return (
    <>
      <FormControl
        size="small"
        className={styles["multi-select-live-container"]}
        style={{
          width: isFullWidth ? "100%" : "",
          marginBottom: "20px",
        }}
      >
        <label className={styles["form-label"]}>
          {label}{" "}
          {isRequired && <span className={styles["required-symbol"]}>*</span>}
        </label>

        <Autocomplete
          multiple={isMultiSelectAllowed}
          filterOptions={(x) => x}
          includeInputInList
          disableCloseOnSelect
          clearOnBlur={false}
          filterSelectedOptions
          popupIcon={<IconPop />}
          sx={{
            [`& .${autocompleteClasses.popupIndicator}`]: { transform: "none" },
          }}
          options={optionsWithCreateButton || []}
          // options={data || []}
          disabled={!isEditable}
          // onChange={(_, values) => onInputChange(values)}
          onChange={handleOptionChange}
          getOptionLabel={(option) => option?.label || ""}
          isOptionEqualToValue={(option, value) =>
            option?.[idParam] === value?.[idParam]
          }
          // value={presentValue || []}
          value={isMultiSelectAllowed ? presentValue || [] : presentValue || ""}
          renderInput={(params) => (
            <TextField
              className={styles["input"]}
              {...params}
              sx={{
                ...MultiSearchDropdownStyles,
                ...(isEditable === false && {
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "rgba(255, 255, 255, 0.4)",
                  },
                }),
              }}
              placeholder={placeholder}
              size="small"
              onChange={(e) => setInputVal(e.target.value)}
            />
          )}
          renderOption={(props, option) => {
            if (option.type === "create-button") {
              return (
                <div style={boxStyle}>
                  <div onClick={handleCreate} style={createButtonStyle}>
                    {option.label}
                  </div>
                </div>
              );
            }
            if (option.type === "not-found") {
              return (
                <div style={boxStyle}>
                  <div style={noOptionsStyle}>{option.label}</div>
                </div>
              );
            }
            return <OptionsDisplayComp data={option} {...props} />;
          }}
        />
      </FormControl>
    </>
  );
};

export default FormMultiselectLive;
