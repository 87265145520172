/* eslint-disable react-hooks/rules-of-hooks */

import React, { useRef, useState, useEffect } from "react";

// components
import Filter from "./Filter";
import Sort from "./Sort";

// styles
import styles from "./style.module.scss";

// assets
import filterIcon from "assets/Tournament/filter.svg";
import sortIcon from "assets/Tournament/sort.svg";

interface IProps {
  reqData: any;
  setReqData: any;
}

const index = ({ reqData, setReqData }: IProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const filterRef = useRef<HTMLDivElement>(null); // Reference for Filter component
  const sortRef = useRef<HTMLDivElement>(null); // Reference for Sort component

  // Close filter or sort if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node)
    ) {
      setIsFilterOpen(false);
    }
    if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
      setIsSortOpen(false);
    }
  };

  // Add event listener on mount and clean up on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilter = () => {
    setIsSortOpen(false);
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSort = () => {
    setIsFilterOpen(false);
    setIsSortOpen(!isSortOpen);
  };

  return (
    <div className={styles["sorting-filter-button-container"]}>
      <div className={styles["sorting-filter-button-wrapper"]}>
        <div className={styles["filter-button"]} onClick={handleFilter}>
          <img src={filterIcon} alt="" />
          Filter
        </div>
        <div className={styles["sort-button"]} onClick={handleSort}>
          <img src={sortIcon} alt="" />
          Sort
        </div>

        {isFilterOpen && (
          <div ref={filterRef}>
            <Filter reqData={reqData} setReqData={setReqData} />
          </div>
        )}

        {isSortOpen && (
          <div ref={sortRef}>
            <Sort
              reqData={reqData}
              setReqData={setReqData}
              setIsSortOpen={setIsSortOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default index;
