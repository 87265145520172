import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";

interface TextAreaProps {
  maxLength: number;
  inputKey: string;
  isRequired: boolean;
  label: string;
  placeholder: string;
  presentValue: string;
  isEditable?: boolean;
  isFullWidth?: boolean;
  onInputChange: any;
  errors?: any;
}

const TextArea = ({
  maxLength,
  inputKey,
  isRequired,
  label,
  placeholder,
  presentValue,
  isEditable,
  isFullWidth,
  onInputChange,
  errors,
}: TextAreaProps) => {
  const [value, setValue] = useState(presentValue || "");

  useEffect(() => {
    // Update the text area value when presentValue changes (e.g., when editing)
    setValue(presentValue || "");
  }, [presentValue]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue(newValue); // Update internal state
    onInputChange(newValue); // Call the onInputChange function with new value
  };

  return (
    <div
      className={styles["text-area-container"]}
      style={{ width: isFullWidth ? "100%" : "" }}
    >
      <label htmlFor={inputKey}>
        {label}
        {isRequired && <span style={{ color: "red" }}> *</span>}
      </label>
      <textarea
        id={inputKey}
        placeholder={placeholder}
        maxLength={maxLength}
        required={isRequired}
        value={value}
        onChange={handleChange}
        disabled={!isEditable}
        className={styles["text-area"]}
        style={{
          background: isEditable ? "" : "#ccc",
        }}
      />
    </div>
  );
};

export default TextArea;
