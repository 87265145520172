import React from "react";
import styles from "./style.module.scss";
import EntityCard from "./EntityCard";
import AddEditButton from "components/AddEditButton";
import NoEntity from "../NoEntity";

interface IProps {
  heading: string;
  entities: any;
  editQuery: string;
  tournamentId: string | undefined;
}

const index = ({ heading, entities, editQuery, tournamentId }: IProps) => {
  return (
    <div className={styles["entity-container"]}>
      <div className={styles["entity-heading-wapper"]}>
        <div className={styles["entity-heading"]}>{heading}</div>
        <AddEditButton text="Add / Edit" editProperty={editQuery} />
      </div>
      <div className={styles["entity-wrapper"]}>
        {entities?.length ? (
          entities?.map((entity: any, index: number) => {
            return (
              <EntityCard
                key={entity.name + index}
                data={entity}
                heading={heading}
                tournamentId={tournamentId}
              />
            );
          })
        ) : (
          <NoEntity text={heading} />
        )}
      </div>
    </div>
  );
};

export default index;
