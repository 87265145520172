import React from "react";
import styles from "./style.module.scss";
import AddEditButton from "components/AddEditButton";
import StatusChip from "components/StatusChip";

interface IProps {
  data: any;
}

const index = ({ data }: IProps) => {
  const extStyles: any = {
    position: "absolute",
    top: "22px",
    right: "22px",
    color: "#fff",
  };
  return (
    <div className={styles["tournament-hero-container"]}>
      <div className={styles["tournament-hero-wrapper"]}>
        <div className={styles["tournament-logo"]}>
          <img src={data?.logo} alt="logo" />
        </div>

        <div className={styles["tournament-details"]}>
          <div className={styles["tournament-name-city"]}>
            <div className={styles["tournament-name"]}>{data?.name}</div>
            <div className={styles["tournament-city"]}>{data?.city}</div>
          </div>
          <div className={styles["tournament-venue-social"]}>
            <div className={styles["tournament-venue"]}>{data?.venue}</div>
            {data?.social?.map((social: any) => {
              return (
                <div
                  key={social?.name}
                  className={styles["tournament-social"]}
                  onClick={() => {
                    social?.url && window.open(social?.url, "_blank");
                  }}
                >
                  <img src={social?.icon} alt="" />
                </div>
              );
            })}
          </div>

          <StatusChip text={data?.status} extStyles={extStyles} />

          <div className={styles["social-add-edit"]}>
            <AddEditButton
              text="Add/Edit Social Media"
              editProperty="Social Media"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
