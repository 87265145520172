/* eslint-disable no-unused-vars */
import React from "react";
import { Box } from "@mui/material";

import MapIcon from "assets/MapPinLine.svg";
import ContactIcon from "assets/contact.svg";

import styles from "./style.module.scss";

function EntityDropDown(props: any) {
  let image = props?.data?.image;
  let location = props?.data?.location;
  let phone = props?.data?.phone;
  let label = props?.data?.label;

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        background: "#2F2F2F",
        padding: "12px 16px",
        height: "66px",
        border: "none",
      }}
      {...props}
    >
      <div className={styles["entity-image"]}>
        <img loading="lazy" src={image} alt="" />
      </div>

      <div className={styles["entity-details-container"]}>
        <div className={styles["entity-name"]}>{label}</div>
        <div className={styles["entity-sub-details"]}>
          {location && (
            <div className={styles["entity-location"]}>
              <img src={MapIcon} alt="" />
              {location}
            </div>
          )}

          {phone && (
            <div className={styles["entity-phone"]}>
              <img src={ContactIcon} alt="" width={14} height={14} />
              {phone}
            </div>
          )}
        </div>
      </div>

      {/* <div
        style={{
          color: colors.grayNinety,
          fontWeight: 600,
          fontSize: "1.6rem",
        }}
      >
        {label}
        <div
          style={{
            color: colors.graySixty,
            fontWeight: 400,
            fontSize: "1.4rem",
          }}
        >
          {location ? (
            <span>
              <img
                src={MapIcon}
                alt="map-icon"
                style={{ verticalAlign: "middle" }}
              />
              {location}
            </span>
          ) : (
            <></>
          )}
          &nbsp;
          {id ? (
            <span>
              <img
                src={ContactIcon}
                alt="contact-icon"
                style={{ verticalAlign: "middle" }}
              />{" "}
              {id}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div> */}
    </Box>
  );
}

export default EntityDropDown;
