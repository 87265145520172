/* eslint-disable react-hooks/rules-of-hooks */

// navigation link
// toast types - success, failure, warning

import React from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

interface IndexProps {
  isModalOpen: boolean;
  setIsModalOpen: any;
  message: string;
  navigationLink?: string;
  variant: string;
  onTriggerSubmit?: any;
}

const index = ({
  isModalOpen,
  setIsModalOpen,
  message,
  navigationLink,
  variant,
  onTriggerSubmit,
}: IndexProps) => {
  const navigate = useNavigate();

  const handleNext = () => {
    setIsModalOpen(false);
    if (navigationLink) {
      navigationLink === "reload" ? navigate(0) : navigate(navigationLink);
    }
  };

  const handleExit = () => {
    setIsModalOpen(false);
    if (navigationLink) {
      navigationLink === "go-back" ? navigate(-1) : navigate(navigationLink);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRetry = () => {
    if (onTriggerSubmit) {
      setIsModalOpen(false);
      onTriggerSubmit(); // Trigger form submission
    }
  };

  const customStyles = {
    content: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      background: "rgba(11, 11, 11, 0.50)",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      border: "none",
      borderRadius: 0,
      inset: 0,
      backdropFilter: "blur(2px)",
    },
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleNext}
      contentLabel="Edit Timer"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles["toast-container"]}>
        <div className={styles["toast-message"]}>{message}</div>
        <div className={styles["toast-buttons-container"]}>
          {variant === "submit-success" && (
            <div className={styles["toast-next-button"]} onClick={handleNext}>
              Next!
            </div>
          )}

          {variant === "submit-failure" && (
            <>
              <div className={styles["toast-next-button"]} onClick={handleNext}>
                Cancel
              </div>

              <div
                className={styles["toast-exit-button"]}
                onClick={handleRetry}
              >
                Retry
              </div>
            </>
          )}

          {variant === "warning" && (
            <>
              <div
                className={styles["toast-cancel-button"]}
                onClick={handleCancel}
              >
                Cancel
              </div>

              <div className={styles["toast-exit-button"]} onClick={handleExit}>
                Exit
              </div>
            </>
          )}

          {variant === "fetch-failure" && (
            <div className={styles["toast-next-button"]} onClick={handleNext}>
              Go Back
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default index;
