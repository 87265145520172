/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";

// styles
import styles from "./style.module.scss";

// assets
import chevronDownGray from "assets/Tournament/chevronDownGray.svg";

interface IProps {
  reqData: any;
  setReqData: any;
  setIsSortOpen: any;
}

const index = ({ reqData, setReqData, setIsSortOpen }: IProps) => {
  const [selectedSort, setSelectedSort] = useState<{
    [key: string]: string;
  }>(reqData?.sortby);

  const handleSortSelection = (key: string) => {
    setSelectedSort((prev) => ({
      key,
      type: prev.key === key && prev.type === "ASC" ? "DESC" : "ASC",
    }));
  };

  const applySort = () => {
    setReqData((prev: any) => ({
      ...prev,
      sortby: selectedSort,
    }));
    setIsSortOpen(false);
  };

  const sortCategories = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "createdAt",
      label: "Created Date",
    },
    {
      name: "startDate",
      label: "Start Date",
    },
  ];

  return (
    <div className={styles["sort-container"]}>
      <div className={styles["sort-options"]}>
        {sortCategories?.map((option: any) => {
          return (
            <div
              key={option?.name}
              className={`${styles["sort-option"]} ${
                option?.name === selectedSort?.key
                  ? styles["sort-option-selected"]
                  : ""
              }
            `}
              onClick={() => handleSortSelection(option?.name)}
            >
              {option?.label}

              {option?.name === selectedSort?.key && (
                <span>
                  {selectedSort?.type === "ASC" ? (
                    <img
                      src={chevronDownGray}
                      alt=""
                      style={{
                        transform: "rotate(180deg)",
                      }}
                      width={12}
                    />
                  ) : (
                    <img src={chevronDownGray} alt="" width={12} />
                  )}
                </span>
              )}
            </div>
          );
        })}
        <div className={styles["sort-apply-button"]} onClick={applySort}>
          Apply
        </div>
      </div>
    </div>
  );
};

export default index;
