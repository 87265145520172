/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.scss";

interface IProps {
  data: any;
  heading: string;
  tournamentId: string | undefined;
}

const index = ({ data, heading, tournamentId }: IProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("functio called");
    if (heading === "Teams") {
      const data2 = {
        query: "Squads",
        teamId: data.id,
      };

      navigate(`/tournament-v3/edit/${tournamentId}`, {
        state: { message: data2 },
      });
    } else if (heading === "Sponsors") {
      window.open(data?.url, "_blank");
    }
  };
  return (
    <div
      className={styles["entity-card-container"]}
      onClick={() => {
        handleClick();
      }}
    >
      <div className={styles["entity-image"]}>
        <img src={data?.logo} alt="entity-logo" />
      </div>
      <div className={styles["entity-name"]}>{data?.name}</div>
    </div>
  );
};

export default index;
