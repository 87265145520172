/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate } from "react-router-dom";

// components
import ListingTable from "./ListingTable";
import SortAndFilter from "./SortAndFilter";

// styles
import styles from "./style.module.scss";

interface indexProps {
  tournamentId: string | undefined;
  tournamentDetails: any;
  reqData: any;
  setReqData: any;
}

const index = ({
  tournamentId,
  tournamentDetails,
  reqData,
  setReqData,
}: indexProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles["preview-container"]}>
      <div className={styles["middle-section-container"]}>
        <div className={styles["page-header"]}>
          <div className={styles["top-header"]}>
            <div className={styles["heading-text"]}>Tournaments</div>
            <div
              className={styles["add-tournament-button"]}
              onClick={() => navigate(`/tournament-v3/create`)}
            >
              Add Tournament +
            </div>
          </div>
          <SortAndFilter reqData={reqData} setReqData={setReqData} />
        </div>

        <div className={styles["middle-section-wrapper"]}>
          <ListingTable
            tournamentDetails={tournamentDetails}
            setReqData={setReqData}
          />
        </div>
      </div>
    </div>
  );
};

export default index;
