/* eslint-disable react-hooks/rules-of-hooks */

import React from "react";
import Navbar from "components/Navbar";
import ListTournaments from "components/TournamentV3/Listing";
import { useParams } from "react-router-dom";

interface indexProps {
  formType?: string;
}

const index = ({ formType }: indexProps) => {
  const { id } = useParams();

  const styles = {
    height: "100%",
    background: "#0b0b0b",
  };

  const radialBg1 = {
    width: "1088px",
    height: "1088px",
    borderRadius: "1088px",
    background:
      "radial-gradient(99.99% 99.99% at 16.91% 73.39%, #CC4767 0%, #EB434C 10.48%, #FA5545 20.94%, #C34563 30.57%, #BB4E76 40.04%, #A14F7D 47.71%, #9D5F9B 55.55%, #856BA0 64.53%, #73719E 72.53%, #526383 81.51%, #314251 89.68%, #0B0B0B 100%)",
    top: "141px",
    left: "-138px",
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{ ...radialBg1, position: "absolute", filter: "blur(127px)" }}
      ></div>
      <Navbar />
      <main style={{ height: "calc(100vh - 80px)" }}>
        <div style={styles}>
          <ListTournaments tournamentId={id} />
        </div>
      </main>
    </div>
  );
};

export default index;
