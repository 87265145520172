import React from "react";
import styles from "./style.module.scss";

import AddEditButton from "components/AddEditButton";
import hideGray from "assets/Tournament/hideGray.svg";

import StatusChip from "components/StatusChip";

const index = ({ data, tournamentId }: any) => {
  return (
    <div className={styles["match-card-container"]}>
      <div className={styles["action-strip"]}>
        <StatusChip text={data?.status} />

        <div>
          <img src={hideGray} alt="" />
        </div>

        <AddEditButton
          text="Edit"
          editProperty="Match"
          navigationLink={`/${tournamentId}/matches/edit/matchId`}
        />
      </div>

      <div className={styles["detail-strip"]}>
        <div className={styles["team-detail"]}>
          <div className={styles["team-logo"]}>
            <img src={data?.team1?.logo} alt="" />
          </div>
          <div className={styles["team-name"]}>{data?.team1?.shortName}</div>
        </div>

        <div className={styles["match-type-date-time"]}>
          <div className={styles["match-type"]}>{data?.type}</div>
          <div className={styles["match-date-time"]}>
            &nbsp;&nbsp;•&nbsp;&nbsp;{data?.date} | {data?.startTime}
          </div>
        </div>

        <div
          className={styles["team-detail"]}
          style={{ flexDirection: "row-reverse" }}
        >
          <div className={styles["team-logo"]}>
            <img src={data?.team2?.logo} alt="" />
          </div>
          <div className={styles["team-name"]}>{data?.team2?.shortName}</div>
        </div>
      </div>
    </div>
  );
};

export default index;
