/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";

// components
import FormHeading from "./FormHeading";
import AllSteps from "./AllSteps";
import { Oval } from "react-loader-spinner";
import MenuBar from "components/Menubar";

import CreateTeamModal from "components/InputComponentsMultiStep/CreateTeamModal";
import CreatePlayerModal from "components/InputComponentsMultiStep/CreatePlayerModal";
import ToastModal from "components/ToastModal";
import { toast } from "react-toastify";

// styles
import styles from "./style.module.scss";
import { useParams, useLocation } from "react-router-dom";

// services
import {
  getTournamentConfig,
  getTournamentConfigById,
} from "services/tournamentsV3";

// import { config } from "./config/config";

interface CreateTournamentFormProps {
  formType: string;
}

interface TournamentConfig {
  tournamentId?: string;
}

const index = ({ formType }: CreateTournamentFormProps) => {
  const { id: tournamentId } = useParams();

  const location = useLocation();
  const { state } = location;
  const alternateHeading = state?.message?.query;

  const [configData, setConfigData] = useState<any>();
  const [selectedSport, setSelectedSport] = useState<string | null>("IC"); // use this when api calling has to be different for different sports
  const [loading, setLoading] = useState(false);

  const [additionalEntity, setAdditionalEntity] = useState<any>(["none", null]);
  const [newTeam, setNewTeam] = useState<any>(null);

  const [showToastModal, setShowToastModal] = useState(false);

  const getTournementConfigDetails = async ({
    tournamentId,
  }: TournamentConfig) => {
    setLoading(true);
    if (tournamentId) {
      const query = state?.message?.query || "";
      const query2 = state?.message?.teamId || ""; // this is for teamId --> used to add squd in team
      const result = await getTournamentConfigById(tournamentId, query, query2);
      if (result && !result.isError) {
        setConfigData(result?.data?.steps);
        setLoading(false);
      } else {
        if (result?.reason === "TIMEOUT_ERROR") {
          toast.error(result?.errorMessage);
          return;
        }
        setShowToastModal(true);
        setLoading(false);
      }
    }
    //
    else {
      setLoading(true);
      const result = await getTournamentConfig();

      if (result && !result?.isError) {
        setConfigData(result?.data?.steps);
        // setConfigData(config);
        setLoading(false);
      }
      //
      else {
        if (result?.reason === "TIMEOUT_ERROR") {
          toast.error(result?.errorMessage);
          return;
        }
        setShowToastModal(true);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getTournementConfigDetails({ tournamentId });
  }, [formType, tournamentId, selectedSport]);

  if (!configData) {
    return (
      <ToastModal
        isModalOpen={showToastModal}
        setIsModalOpen={setShowToastModal}
        message={"Could not load the form. Please try again later."}
        navigationLink={`/tournament-v3`}
        variant={"fetch-failure"}
      />
    );
  }

  return (
    <>
      <div className={styles["outer-form-container"]}>
        <MenuBar redirectionCheckRequired={true} />
        <div className={styles["main-form-container"]}>
          <FormHeading
            formType={formType}
            alternateHeading={alternateHeading}
          />
          {loading ? (
            <div className={styles["loader-container"]}>
              <Oval
                visible={true}
                height="20"
                width="20"
                color="#c0c0c0"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
                secondaryColor={"#c0c0c0"}
              />
            </div>
          ) : (
            <AllSteps
              config={configData}
              formType={formType}
              tournamentId={tournamentId}
              setAdditionalEntity={setAdditionalEntity}
              newTeam={newTeam}
            />
          )}
        </div>
      </div>
      <CreateTeamModal
        isModalOpen={additionalEntity[0] === "team"}
        setIsModalOpen={setAdditionalEntity}
        setNewTeam={setNewTeam}
        componentId={additionalEntity[1]}
      />
      <CreatePlayerModal
        isModalOpen={additionalEntity[0] === "player"}
        setIsModalOpen={setAdditionalEntity}
        setNewTeam={setNewTeam}
        componentId={additionalEntity[1]}
      />
    </>
  );
};

export default index;
