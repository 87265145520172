/* eslint-disable react-hooks/rules-of-hooks */

import React from "react";
import Navbar from "components/Navbar";
import ViewTournament from "components/TournamentV3/View";
import { useParams } from "react-router-dom";
import previewBg from "assets/Tournament/previewBg.webp";

interface indexProps {
  formType: string;
}

const index = ({ formType }: indexProps) => {
  const { id } = useParams();

  const styles = {
    height: "100%",
    background: "#0b0b0b",
  };

  const radialBg1 = {
    width: "100%",
    height: "100%",

    backgroundImage: `url(${previewBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ ...radialBg1, position: "absolute" }}></div>
      <Navbar navBgColor="rgba(31, 31, 31, 0.60)" />
      <main style={{ height: "calc(100vh - 80px)" }}>
        <div style={styles}>
          <ViewTournament tournamentId={id} />
        </div>
      </main>
    </div>
  );
};

export default index;
