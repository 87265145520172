/* eslint-disable no-undef */
import React, { useState } from "react";

// components
import { Oval } from "react-loader-spinner";

// styles
import styles from "./style.module.scss";

interface IndexProps {
  team: any;
  index: number;
}

const TeamStrip = ({ team, index }: IndexProps) => {
  const [loading, setLoading] = useState(false);
  const [isQualified, setIsQualified] = useState(team?.isQualified);

  const handleQualifiedClick = () => {
    setLoading(true);

    // remove setTimeout and call api here
    setTimeout(() => {
      setIsQualified(!isQualified);
      setLoading(false);
    }, 1000);
  };

  return (
    <div
      className={styles["team-wrapper"]}
      style={{
        background:
          index % 2 === 0 ? "transparent" : "rgba(255, 255, 255, 0.05)",
      }}
    >
      <div className={styles["teams-info"]}>
        <div className={styles["team-logo"]}>
          <img src={team?.logo} alt="team-logo" />
        </div>
        <div className={styles["team-name"]}>{team?.name}</div>
      </div>
      <div className={styles["points-info"]}>{team?.points}</div>
      <div className={styles["qualified-info"]}>
        {loading ? (
          <Oval
            visible={true}
            height="30"
            width="30"
            color="#3ca6ff"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            secondaryColor={"#3ca6ff"}
          />
        ) : (
          <span
            style={{
              background: !isQualified ? "transparent" : "",
            }}
            onClick={handleQualifiedClick}
          >
            {isQualified ? "Q" : ""}
          </span>
        )}
      </div>
    </div>
  );
};

export default TeamStrip;
