import React from "react";
import styles from "./style.module.scss";
import stepDone from "assets/stepDone.svg";

interface StepProgressBarProps {
  currentStepNumber: number;
  setStep: any;
  allStepNames: string[];
}

const index = ({
  currentStepNumber,
  setStep,
  allStepNames,
}: StepProgressBarProps) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["steps-container"]}>
        {allStepNames.map((stepName: any, index: number) => (
          <span className={styles["step-wrapper"]} key={stepName + index}>
            <span className={styles["step-name"]}>{stepName}</span>
            <span className={styles["step-number-line"]}>
              <span
                className={`${styles["step-circle"]} ${
                  index + 1 === currentStepNumber ? styles["active"] : ""
                } ${index + 1 < currentStepNumber ? styles["completed"] : ""}`}
              >
                {index + 1 < currentStepNumber ? (
                  <div className={styles["tick-container"]}>
                    <img src={stepDone} alt="step-done" />
                  </div>
                ) : (
                  <>{index + 1}</>
                )}
              </span>
              {index < allStepNames?.length - 1 && (
                <div className={styles["line"]}></div>
              )}
            </span>
          </span>
        ))}
        {/* <div className={styles["progress-bar-container"]}>
          <span
            className={styles["progress-indicator"]}
            style={{
              width: `${((currentStepNumber - 1) / 3) * 100}%`,
            }}
          ></span>
        </div> */}
      </div>
    </div>
  );
};

export default index;
